@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');


/**/
body {
  font-weight: 300 !important;
  font-size: 16px !important;
  color: #333 !important;
  background-color: #9e9e9e29 !important;
  font-family: Helvetica, Arial, sans-serif !important;
  margin: 0 auto !important;
  /* overflow: auto !important; */
}

.emr-form-error-message {
  color: #d31245;
  line-height: 1.375em;
  font-style: italic;
  letter-spacing: 0.03em;
}
/**/


/*Non Notification*/
.non_notifier {
  text-align:center;
  padding :10px;
  font-weight : 500;
} 
.success_non {
  color: green
  
}
.error_non {
  color: red
  
}
.info_non {
  color: blue
 
}
.warning_non{
  color: amber
  
}
/* end NonNotificaiton*-/

/* header Start */

.app-header {
  background: #FFF !important;
  color: #000 !important;
  padding:4px !important;
  height:75px;
}
 
.app-header-image{
    height: 100% ;
    width: 100% ;
    text-align: center !important;
}
.app-header-logo-border {
  border-right: 2px solid #CCC !important;
}

.app-header-logo {
  padding: 4px 15px 0px 15px !important;
  height: auto;
}

.app-header-grid-padding{
  padding:0 !important;
}

.app-header_margin-left {
  display: flex;
  flex-direction: row-reverse;
}

.app-header-valign{
    display: flex;
}

/* header End */

/* login start*/
.app-content{
  text-align: center;
  display: block;
  margin-left: 10%;
  margin-right: 10%;
  margin-top:0 !important;
  margin-bottom: 0 !important;
}
.app-content-center{
  text-align: center !important;
  font-family: Helvetica, Arial, sans-serif !important;
  font-size: 1.4em !important;
}
.app-content-left{
  text-align: left !important;
}
.app-content-right{
  text-align: right !important;
}
.app-content-h2{
  font-family: Helvetica, Arial, sans-serif !important;
  font-size: 1.4em !important;
  margin-block-end: 0.67em !important;
  font-weight: 300 !important;
  color: #333 !important;;
}
.app-content-h3{
  font-family: Helvetica, Arial, sans-serif !important;
  font-size: 1.1em !important;  
  margin-block-end: 0.67em !important;
  font-weight: 300 !important;
  color: #333 !important;
}
.app-content-h3-profile {
  font-family: Helvetica, Arial, sans-serif !important;
  font-size: 1.2em !important;
  margin-block-end: 0.67em !important;
  font-weight: 300 !important;
  color: #333 !important;
}
.app-content-h3-login {
  font-family: Helvetica, Arial, sans-serif !important;
  font-size: 1.3em !important;
  margin-block-end: 0.67em !important;
  font-weight: 300 !important;
  color: #333333c2 !important;
  padding: 4px;
}

.app-content-h4{
  font-family: Helvetica, Arial, sans-serif !important;
  font-size: 1.00em !important;
  margin-block-end: 0.67em !important;
  font-weight: 50 !important;
  color: #333 !important;
  line-height: 1.2;
  letter-spacing: 0.0075em;
}
.app-content-h4-login{
  font-family: Helvetica, Arial, sans-serif !important;
  font-size: 1em !important;
  margin-block-end: 0.67em !important;
  font-weight: 50 !important;
  color: #333 !important;
  line-height: 1.2;
  letter-spacing: 0.0075em;
}
.app-content-h5{
  font-family: Helvetica, Arial, sans-serif !important;
  font-size: .80em !important;
  margin-block-end: 0.67em !important;
  font-weight: 300 !important;
  color: #333 !important;
}

.app-content-label {
  text-transform: uppercase !important;
  font-weight: 600 !important;
  font-size: .75em !important;
  letter-spacing: .08em !important;
  display: block !important;
  margin-bottom: 7px !important;
  line-height: 100% !important;
  line-height: 22px !important;
  text-align: left !important;
}

.app-content-label-red {
  text-transform: uppercase !important;
  font-weight: 600 !important;
  font-size: .75em !important;
  letter-spacing: .08em !important;
  display: block !important;
  margin-bottom: 7px !important;
  line-height: 100% !important;
  line-height: 22px !important;
  color:red;
  text-align: right !important;
}

.app-content-label-lower {
  font-weight: 200 !important;
  letter-spacing: .07em !important;
  display: block !important;
  font-size: 11px !important;
  padding:0px !important;
  
}

.app-content-text-width{
  width: 100% !important;
  background-color: #f0f0f0 !important;
  height: 47.9px !important;
  outline: 0 !important;
  border: 1px solid #f0f0f0 ;
  font-size:.90em !important;
  padding: 0 10px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

.app-content-text-width-with-error{
  width: 100% !important;
  background-color: #f0f0f0 !important;
  height: 47.9px !important;
  outline: 0 !important;
  border: 1px solid red ;
  font-size:.90em !important;
  padding: 0 10px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

.app-content-dropbox{
  width: 100% !important;
  background-color: #f0f0f0 !important;
  outline: 0 !important;
  border: 1px solid #f0f0f0 !important;
  font-size:.90em !important;
}

.app-content-text-width:active,
.app-content-text-width:focus {
  border-color: silver !important;
  border-width: 1px !important;
} 

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #f0f0f0 inset !important;
}


.app-content-text-readonly{
  width: 100% !important;
  background-color: white !important;
  outline: 0 !important;
  color: black !important;
  border : 0px !important;
  font-weight: 200 !important;
  font-size: 11px !important;
  letter-spacing: .07em !important;
}

.app-content-select{
  width: 100% !important;
  background-color: #f0f0f0 !important;
  height: 50px !important;
  outline: 0 !important;
  border: 1px solid #f0f0f0 !important;
}

.app-button-primary{
  background-color: #00aa7e !important;
  border-color: #00aa7e !important;
  color: #fff !important;
  width:100% !important;
  line-height: 1.1429em !important;
  font-size: 12px !important;
  letter-spacing: .05em !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  padding: 0 25px !important;
  box-sizing: border-box !important;
  white-space: normal !important;
  height:50px !important; 
}
.app-button-outline{
  background-color:transparent !important;
  border: 1px solid #000 !important;
  color: #000 !important;
  width:100% !important;
  line-height: 1.1429em !important;
  font-size: 14px !important;
  letter-spacing: .05em !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  padding: 0 25px !important;
  box-sizing: border-box !important;
  white-space: normal !important;
  height:50px !important; 
}
.app-button-cancel {
  background-color: #FFF !important;
  border-color: #2d383f !important;
  border: 1px solid #2d383f !important;
  color: #000 !important;
  width:100% !important;
  line-height: 1.1429em !important;
  font-size: 12px !important;
  letter-spacing: .05em !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  padding: 0 25px !important;
  box-sizing: border-box !important;
  white-space: normal !important;
  height:50px !important; 
}

.app-button-href{
  color: #00aa7e !important;
  font-size: .70em !important;
  text-decoration: none !important;
  font-family: Helvetica,Arial,sans-serif !important;
  margin-block-end: 0.67em !important;
  font-weight: 50 !important;
  padding: 4px 4px 4px 4px;
  border: none;
  background: transparent;
  cursor: pointer;
}


/*login end*/

/*forgot password*/

.app-container-fp {
  margin-top: 15px;
}
.email-sent-alert-icon {
  font-size: 35; 
  color: green;
  margin-top: 24px; 
}
.email-sent-alert-title {
  font-size: 13px;
  font-weight: 600;
  color: #4a4545;
  float: left;
}
.email-sent-alert-desc {
  text-align: justify;
  margin-top: 7%;
  font-size: 13px;
}

/*end*/

/**/

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .app-header-logo {
    padding: 4px !important;
    height: auto;
  }
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .app-header-logo {
    padding: 4px  !important;
    height: auto;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .app-header-logo {
    padding: 4px  !important;
    height: auto;
  }
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .app-header-logo {
    padding: 4px !important;
    height: auto;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .app-header-logo {
     padding: 4px !important; 
      height: auto;
}
.app-header {
  padding:4px !important;
  height:65px;
}

}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .app-header-logo {
    padding: 0px 0px 0px 0px !important; 
    height: auto;
  }

  .app-header {
    padding:4px !important;
    height: 60px;
  }
}


@media (min-width: 0px) and (max-width: 319px) {
  .app-header-logo {
    padding: 0px 0px 0px 0px !important; 
    height: auto;
    width: auto;
  }

  .app-header {
    padding:4px !important;
    height: 60px;
  }


}
/**/

/**/
.app-profile-image {
  margin: 10;
  width: 60;
  height: 60;
}
.app-content-padding-profile{
  padding:5px;
}
/* Login End */
/**/



/* Footer START */
.footer-links { 
  display: flex;
}

.nav-link {
  width: 80% !important;
}

.nav-link-right {
  border-color: white !important;
  border-width: 0px 1px 0px 0px !important;
  border-style: solid !important;
}

.ft-container { 
  margin: 0;
}

.ft-copyright-container {
  min-height: 40px !important;
}

.ft-copyright {
  padding: 0px 16px !important;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .nav-link {
    width: 80% !important;
  }
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .nav-link {
    width: 80% !important;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .nav-link {
    width: 80% !important;
  }
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .nav-link {
    width: 77% !important;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .nav-link {
    width: 80% !important;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .nav-link {
    width: 89% !important;
  }
}


/**/
/* .app-table-bottom-border{
  border-bottom: none !important;
  box-shadow:none !important
}

.MuiTableCell-root{
  border-bottom: 0px !important;
} */
/**/
/* mui Datatable */
.MuiCardContent-root {
  padding: 3px !important;
}
.MuiCardContent-root:last-child {
  padding-bottom: 2px !important;
}
@media (min-width: 600px){
  .MuiToolbar-gutters {
    padding-left: 16px!important;
}
}
/* mui END */


/* table Start*/
.app-table-header{
  padding: 0px 15px 0px 15px !important;
}

.app-content-text-table{
  width: 100% !important;
  background-color: #CCC !important;
  height: 35px !important;
  outline: 0 !important;
  border: 1px solid grey !important;
  font-size:.90em !important;
  padding: 0 10px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}
.app-content-h3-table-head {
  font-family: Helvetica, Arial, sans-serif !important;
  font-size: 1em !important;
  margin-block-end: 0.67em !important;
  font-weight: 500 !important;
  color: #333 !important;
  padding: 10px 4px 4px 4px;
}

.MuiTable-root{
  margin-top: 50px !important; 
}

.ReactMUIDatatableToolbarSelect-highlight-432 {
  color: #000 !important;
  background-color: #DDD !important;
}



.ReactMUIDatatableToolbarSelect-highlight-420 {
  color: #000 !important; 
  background-color: #CCC !important
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #DDD !important;
}

.ReactMUIDatatableToolbarSelect-highlight-456 {
  color: #000 !important;
  background-color: #DDD !important;
}

.ReactMUIDatatableToolbarSelect-highlight-420 {
  color: #000 !important
}


.ReactMUIDatatableToolbarSelect-highlight-301 {
  color: #000 !important;
  background-color: #DDD !important;
}

.app-icon-href{
  cursor: pointer; ;
}

a{
  color: black;;
}

/* table End*/

/* Footer END */

/* Dynamic Form START */

.dynamic-form-container {
  background: #FFF !important;
}

.dynamic-ctrl-item {
  padding: 10px 15px !important;
}

.dynamic-form-group {
  border: #e6e6e6 1px solid;
  border-radius: 5px;
}
/* Dynamic Form END */

/* Reusable Component START */

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.alert-danger {
  color: #d31245;
  padding-left: 0px;
}

.emr-field-error-message {
  color: #d31245;
  line-height: 1.375em;
  font-style: italic;
  margin-top: 7px;
  text-align: left;
}
/* Reusable Component END */

/* MessageComponent START */
.messsage-section {
  margin-bottom: 15px;
}

.message-container {
  background: orange;
  color: white;
  /* padding: 10px 10px 0px 0px; */
}
.err-message-container {
  color: #d31245;
  line-height: 1.375em;
  font-style: italic;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.message-icon-container {
  display: flex;
  justify-content: center;
  align-self: center;
}

.message-icon {
  height: 40px;
  width: 40px;
  font-size: 28px;
  font-weight: 600;
  border-radius: 25px;
  background: white;
  color: orange;
}

.message-icon > span {
  vertical-align: middle;
}

.message-header {
  text-align: left;
  font-weight: 600;
  margin-bottom: 7px;
}

.message-description {
  text-align: left;
}


/* MessageComponent END */

/*Dynamic Form*/
.disableInupt{
  background-color: white !important;
  border: 1px solid #ffffffff !important;
  border: none;
  padding: 0;

}

/* Profile Header START */

.profile-header-container {
  padding: 15px;
}

.profile-avatar-container {
  display: flex;
}

.profile-header-title {
  font-size: 16px;
  font-weight: 600;
}
.profile-header-sub-title {
  font-size: 12px;
  font-weight: 600;
}
.profile-header-description {
  font-size: 12px;
  font-weight: 500;
  padding-top: 10px;
}

.profile-link-container {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.profile-link {
  color: rgb(0, 158, 117);
  font-size: 12px;
  text-decoration: none;
}
/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
  .profile-info-container {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: initial;
  }

  .profile-avatar-container {
    justify-content: flex-start
  }
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
  .profile-info-container {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: initial;
  }

  .profile-avatar-container {
    justify-content: flex-start
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
  .profile-info-container {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profile-avatar-container {
    justify-content: center
  }

  .profile-link-container {
    justify-content: center
  }
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  .profile-info-container {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: initial;
  }

  .profile-avatar-container {
    justify-content: center
  }

  .profile-link-container {
    justify-content: center
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  
  .profile-info-container {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profile-avatar-container {
    justify-content: center
  }

  .profile-link-container {
    justify-content: center
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  
  .profile-info-container {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profile-avatar-container {
    justify-content: center
  }

  .profile-link-container {
    justify-content: center
  }
}
/* Profile Header END */

/* Dialog START */
.dialog-title {
  text-align : center;
  font-size : 13px !important;
  font-weight : 600 !important;
  background-color: #8080801f ;
  height: 12px !important;
}
.close-icon {
  float : right !important;
  padding : 0px !important;
}
.dialog-no-scroll-bar {
  overflow: hidden !important;
}
/*  Dialog END */


/* COMMON START */
.content-area {
  margin-top: 15px !important;
}

.shadow-bordered-container {
  box-shadow: 1px 1px 5px 1px rgba(186,186,186,1) !important;
}

.status-container {
  text-align: center;
  color: #114885;

  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.status-icon {
  font-size: 50px !important;
  color: #114885;
}

.dummy-password {
  font-size: 25px !important;
  font-weight: 600;
}

.dynamic-group-title {
  padding: 10px 15px 0px 15px;
}

.error-message-section {
  margin-bottom: 10px;
}

/* COMMON END */

/* Registration starts */

.form-control {
  width: 100% !important;
  height: 47.9px !important;
  outline: 0 !important;
  border: 1px solid #f0f0f0 ;
  font-size:.90em !important;
  padding: 0 10px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ .react-tel-input input[type=text] .react-tel-input input[type=tel]*/
}
.form-control:not(:disabled){
  background-color: #f0f0f0 !important;
}

.react-tel-input input[type=tel].form-control:disabled,
.react-tel-input input[type=tel].form-control:disabled + .flag-dropdown{
  cursor: not-allowed;
  background-color: white !important;
}
.react-tel-input input[type=tel] {
  border: 1px solid #ffff !important;
  border-radius: 0 !important;
} .flag-dropdown  {
  border: 1px solid #ffff !important;
}

/* Registration ends */

/* IE Support Start */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ie10up {
    margin-bottom: 0.67em !important ;
  }
  select::-ms-expand { display: none; }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+);
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    margin-right: 2rem;
    padding: 1rem;
    padding-right: 2rem;
  }
}

#root {
  display: flex;
  flex-direction: column;
}
/* IE Support End */

i {
  position: absolute;
  top: 38%;
  right: 8%;
}
.pass-wrapper {
  position: relative;
  display: flex;
}
.fiels-required-span{
  position: absolute;
  margin-left: -8px;
  margin-top: 5px;
  color: red;
}
